/* :root {
  --hue: 222;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 55%);
  --trans-dur: 0.3s;
} */

body .kiosk-mode {
  background-color: var(--bs-white);
  height: 100%;
  width: 100%;
  display: table;
}


#root {
  /* height: 100vh; */
  width: 100%;
  /* display: inline-table; */
  /* margin-bottom: 2rem; */
}

*:disabled {
  opacity: .5;
}

img[disabled] {
  opacity: 0.5;
}

input::placeholder {
  color: #3F4444BF;
}


.MuiTextarea-root::before {
  box-shadow: none !important;
}

/* Chrome/Safari and web-kit-based browsers  */
::-webkit-file-upload-button {
  cursor: pointer;
}

.PrivateSwipeArea-root {
  width: 0 !important;
}

.MuiPaper-elevation::before {
  background-color: transparent !important;
}

.MuiAccordionDetails-root {
  padding-top: 0;
}

.MuiChip-root {
  /* background-color: var(--bs-lightBrown) !important; */
}

.MuiAlert-message {
  width: 100%;
  padding: 8px 0 !important;
}

.MuiAlert-action {
  display: none !important;
}

.MuiSelect-select {
  padding-left: 20px !important;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--sub-heading-family) !important;
}

.MuiAlert-action button {
  padding: 5px !important;
  color: var(--bs-white) !important;
}

.MuiAlert-icon {
  display: none !important;
}

.MuiMenuItem-root {
  padding: 10px 15px !important;
  min-height: 40px !important;
  font-size: 1rem !important;
  display: flex;
  align-items: center;
  font-family: var(--sub-heading-family) !important;
  white-space: normal !important;
  /* background-color: var(--bs-transparent) !important; */
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

fieldset {
  outline: none !important;
  border: none !important;
}

body .slick-initialized .slick-slide > div {
  padding: 0 10px !important;
}
/* body .slick-list .slick-track::before {
  content: '';
  top: 55%;
  left: 0;
  transform: rotate(90deg);
  height: 30px;
  width: 33px;
  border-radius: 150px 150px 0 0;
  background-color: #f7f7f7;
  position: absolute;
  z-index: 9;
} */
.MuiDayCalendar-weekDayLabel,
.MuiPickersCalendarHeader-label,
.MuiIconButton-edgeStart,
.MuiIconButton-edgeEnd {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
  font-weight: 500 !important;
  /* font-family: var(--main-heading-family) !important; */
  color: var(--bs-font-color) !important;
}

.MuiPickersCalendarHeader-root {
  margin-bottom: 20px;
}

.MuiTypography {
  font-size: 0.875rem !important;
  line-height: 1.188rem !important;
  font-weight: 700;
  font-family: var(--sub-heading-family) !important;
}

body .MuiSelect-select:after {
  border-left: 0;
  border-color: #ccc transparent;
  border-radius: 4px;
  border-style: solid;
  border-width: 8px;
  content: "";
  margin: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -20%);
  transform: translate(-50%, -20%);
  transition: .3s ease;
}

body .customSelect .MuiSelect-select:after {
  border-color: #fff transparent;
}

body .MuiSelect-select[aria-expanded=false]:after {
  border-bottom-color: var(--bs-transparent);
}

body .MuiSelect-select[aria-expanded=true]:after {
  border-top-color: var(--bs-transparent);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.MuiSelect-icon,
.MuiPickersToolbar-root {
  /* display: none !important; */
}

.MuiDayCalendar-slideTransition {
  min-height: 220px;
}

body .MuiPickersDay-today,
body .MuiPickersDay-root.Mui-selected {
  font-size: 1rem !important;
}

.MuiPickersDay-today,
.MuiPickersDay-root.Mui-selected {
  border: none !important;
  background-color: var(--bs-white) !important;
  font-size: 1rem !important;
}

.MuiPickersYear-yearButton.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root:hover {
  /* background-color: var(--bs-yellow) !important; */
  border: none !important;
  /* color: var(--bs-font-color) !important;y */
}

.MuiDialogActions-spacing button {
  border-radius: 30px;
  box-shadow: 0 1px 2px rgb(16 24 40/5%);
  color: var(--bs-font-color) !important;
  /* font-family: var(--main-heading-family) !important; */
  /* font-size: 20px !important;
  line-height: 20px !important; */
  padding: 10px;
  width: 50%;
  margin: 0 0 10px;
}

.MuiDialogActions-spacing button:first-child {
  /* background-color: var(--bs-lightBrown) !important; */
}

.MuiDialogActions-spacing button:last-child {
  /* background-color: var(--bs-yellow) !important; */
}

.MuiPopover-root {
  /* z-index: 9 !important; */
}

.MuiPopover-paper {
  border-radius: 10px !important;
  /* min-height: 75px !important; */
  /* overflow: inherit !important; */
}

#file-upload-button,
#file-upload-button span {
  width: 100%;
  color: var(--bs-white);
}

.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
  min-height: 200px !important;
}

.Mui-selected.MuiMenuItem-root {
  /* background-color: var(--bs-yellow) !important; */
}

.Mui-disabled {
  opacity: .5;
}

.MuiSnackbar-root {
  /* width: 100% !important;
  left: 0 !important; */
}

.MuiSnackbar-root .MuiPaper-rounded {
  border-radius: 5px !important;
  margin: 0 20px !important;
}

.MuiList-padding {
  padding: 10px 0 !important;
  max-height: 300px;
}

.MuiAccordionDetails-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.MuiAccordion-root.Mui-expanded:last-of-type {
  margin-bottom: 20px !important;
}

.body-top-padding {
  height: calc(100vh - 85px);
  padding-top: 5.2rem !important;
}

.body-top-padding.acecard-padding-add {
  height: auto;
  /* width: 100%;
  display: table; */
  padding-bottom: 20px;
}

.card-popover {
  z-index: 1200 !important;
  position: inherit !important;
}

.card-popover>div {
  position: fixed;
  top: 82px !important;
  border-radius: 5px !important;
  right: 25px !important;
  left: auto !important;
  z-index: 10;
}

.acecard-padding-add .card-popover>div {
  top: 75px !important;
}

.MuiAccordionSummary-expandIconWrapper img {
  height: 20px;
}

.MuiTabs-scrollButtonsHideMobile {
  width: 0 !important;
}

.MuiClock-amButton,
.MuiClock-pmButton {
  background-color: transparent !important;
}

.MuiClock-squareMask {
  cursor: pointer;
  border-radius: 50%;
  background: var(--bs-lightGray);
}

.MuiClock-amButton span,
.MuiClock-pmButton span {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--bs-font-color) !important;
  font-family: var(--sub-heading-family) !important;
}

body .MuiPickersCalendarHeader-label {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: var(--bs-font-color) !important;
  font-family: var(--sub-heading-family) !important;
}

body .MuiPickersDay-root {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: var(--bs-font-color) !important;
  font-family: var(--sub-heading-family) !important;
}

body .MuiClockNumber-root {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: var(--sub-heading-family) !important;
}

body .MuiDayCalendar-weekDayLabel {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: var(--bs-font-color) !important;
  font-family: var(--sub-heading-family) !important;
}

.MuiInputBase-input {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: var(--bs-font-color) !important;
  opacity: 1 !important;
}

.MuiInputBase-input::placeholder {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: var(--bs-font-color) !important;
  opacity: 1 !important;
}

/* .MuiDialogActions-root {
  display: none !important;
} */
.MuiInputBase-root .MuiSvgIcon-fontSizeMedium{
  display: none !important;
}
body #placeholder {
  color: var(--bs-font-color) !important;
}

.reservationbtn {
  width: 90px;
  height: 90px;
  display: flex;
  position: fixed;
  bottom: 5%;
  right: 5%;
  background-color: black;
  color: white !important;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.sticky {
  position: fixed;
  top: 80px;
  background-color: black;
  color: #fff;
  height: 50px;
  width: 100%;
}

@media(min-width:1280px) {
  .MuiContainer-root {
    max-width: 85% !important;
  }

  .kiosk-mode .MuiContainer-root {
    max-width: 100% !important;
  }
}

@media(max-width:640px) {
  #root {
    width: 100%;
    height: 100%;
    display: block;
  }

  .MuiAlert-message button {
    padding: 0 !important;
    color: var(--bs-white);
  }

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .body-top-padding.acecard-padding-add {
    padding-bottom: 9rem !important;
    width: 100%;
    display: table;
  }

  body .kiosk-mode {
    display: block;
  }

  .MuiMenuItem-root {
    font-size: 14px;
  }

  .MuiAccordionSummary-expandIconWrapper img {
    height: 15px;
  }
}

@media(max-width:575px) {
  .body-top-padding {
    padding-top: 9rem !important;
  }

  .home.acecard-padding-add {
    /* padding-top: 8rem !important; */
  }

  .body-top-padding.acecard-padding-add {
    padding-top: 8rem !important;
  }

  .card-popover>div {
    top: 142px !important;
  }

  .ace-padding-top .card-popover>div {
    top: 135px !important;
  }
}

@media(max-width:475px) {
  .ace-padding-top .card-popover>div {
    top: 132px !important;
  }
}

@media(max-width:375px) {
  .card-popover>div {
    top: 132px !important;
    right: 20px !important;
  }

  .ace-padding-top .card-popover>div {
    top: 125px !important;
  }
}