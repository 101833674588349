.slick-dots,
.slick-next,
.slick-prev {
    position: absolute;
    display: block;
    padding: 0;
}

.slick-next,
.slick-prev {
    /* display: none !important; */
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
    opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25;
}

.slick-next:before,
.slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: var(--bs-white);
}

.slick-prev {
    left: 0;
}

[dir=rtl] .slick-prev {
    right: 0;
    left: auto;
    display: none;
}

.slick-prev:before {
    content: '←';
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: 0;
    display: none;
}

[dir=rtl] .slick-next {
    right: auto;
    left: 0;
}

[dir=rtl] .slick-next:before {
    content: '←';
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    list-style: none;
    bottom: -45px;
    width: 100%;
    left: 50%;
    list-style: none;
    max-width: 1488px;
    transform: translateX(-53%);
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0 !important;
    line-height: 0 !important;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px !important;
    cursor: pointer;
    color: var(--bs-transparent) !important;
    background-color: var(--bs-transparent) !important;
    border: 0;
    outline: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100% !important;
    transform: translate(-50%, -50%);
}

/* .slick-dots li button:focus,
.slick-dots li button:hover {
    outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
    opacity: 1
} */

/* .slick-dots li button:before {
    content: '•';
    text-align: center;
    opacity: .25;
    display: flex;
    align-items: center;
    font-size: 65px;
    justify-content: center;
    color: var(--bs-black);
} */

.slick-dots li.slick-active button {
    opacity: 1;
}

.slick-dots li:first-child {
    margin-left: 0;
}

@media (max-width: 1480px) {
    .slick-dots li:first-child {
        margin-left: 24px;
    }
}

@media (max-width: 640px) {
    .slick-dots li {
        width: 16px;
        height: 16px;
    }

    .slick-dots li button {
        width: 12px;
        height: 12px;
    }

    .slick-dots li button:before {
        content: '•';
        font-size: 40px;
    }

    .slick-dots li:first-child {
        margin-left: 16px;
    }
}