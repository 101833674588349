:root {
    --bs-white: #fff;
    --bs-black: #000000;
    --bs-transparent: transparent;
    --bs-lightBrown: rgba(241, 238, 219, 1);
    --bs-yellow: rgba(255, 194, 0, 1);
    --bs-lightGray: rgba(247, 247, 247, 1);
    --bs-font-color: #3F4444;
    --bs-darkGray: #3F4444BF;
    --bs-gray-color: #D5D4DC;
    --bs-steel: #FFFFFF40;
    --bs-pink-color: #FF6363;
    --bs-red: #D00606;
    --bs-blue-color: rgb(25, 118, 210);
    --bs-disble: #ddd;
    --color-alpha: 0.5;
    /* --bs-h1FontSize: '82px';
    --bs-h1LineHeight: '86px';
    --bs-h2FontSize: '32px';
    --bs-h2LineHeight: '36px';
    --bs-h3FontSize: '30px';
    --bs-h3LineHeight: '35px';
    --bs-h4FontSize: '26px';
    --bs-h4LineHeight: '30px';
    --bs-h5FontSize: '22px';
    --bs-h5LineHeight: '25px';
    --bs-h6FontSize: '16px';
    --bs-h6LineHeight: '20px'; */
    /* --main-heading-family: "Bebas Neue", serif; */
    /* --sub-heading-family: "Noto Serif", serif; */
    /* --main-heading-family: "Inter", sans-serif;
    --sub-heading-family: "Inter", sans-serif; */
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    color: var(--bs-font-color);
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: var(--sub-heading-family);
    line-height: normal;
    background-color: var(--bs-lightGray);
    padding-right: 0 !important;
    overflow-x: hidden !important;
    letter-spacing: 0;
}

a {
    display: inline-block;
    text-decoration: none;
    font-size: var(--bs-subtitle1);
    line-height: 1.188rem;
    font-weight: 400;
    color: var(--bs-font-color);
    font-family: var(--sub-heading-family);
}

ul,
li {
    list-style: none;
}

.listDots {
    padding-left: 1.5rem;
}

.listDots li {
    list-style: disc !important;

}

.listDots li::marker {
    color: var(--bs-darkGray);
    font-size: 1.125rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: var(--bs-font-color);
    font-weight: 500;
    font-family: var(--main-heading-family);
    text-transform: capitalize !important;
    line-height: normal;
}


h1 {
    font-size: var(--bs-h1FontSize);
    /* line-height: var(--bs-h1LineHeight); */
}

h2 {
    font-size: var(--bs-h2FontSize);
    /* line-height: var(--bs-h2LineHeight); */
}

h3 {
    font-size: var(--bs-h3FontSize);
    /* line-height: var(--bs-h3LineHeight); */
}

h4 {
    font-size: var(--bs-h4FontSize);
    /* line-height: var(--bs-h4LineHeight); */
}

h5 {
    font-size: var(--bs-h5FontSize);
    /* line-height: var(--bs-h5LineHeight); */
}

h6 {
    font-size: var(--bs-h6FontSize);
    /* line-height: var(--bs-h6LineHeight); */
}

p {
    font-size: var(--bs-subtitle1);
    /* line-height: 1.5rem; */
    font-weight: 400;
    color: var(--bs-darkGray);
}

body button {
    font-size: var(--bs-buttonFont) !important;
    line-height: normal !important;
    color: var(--bs-darkGray) !important;
    font-family: var(--sub-heading) !important;
    font-weight: 500 !important;
    color: var(--bs-font-color) !important;
    text-transform: capitalize !important;
    border: 2px solid transparent;
}

/* arrow hide in input type Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.p0 {
    padding: 0 !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.pX16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.py0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py10 {
    padding: 10px 0 !important;
}

.py20 {
    padding: 20px 0 !important;
}

.px20 {
    padding: 0 20px !important;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl20 {
    padding-left: 20px;
}

.pr10 {
    padding-right: 10px;
}
.m0{
    margin: 0;
}
.m20 {
    margin: 20px;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}

.mt70 {
    margin-top: 70px !important;
}
.mt1rem {
    margin-top: 1rem;
}

.mt2rem {
    margin-top: 2rem;
}

.mt6rem {
    margin-top: 6rem;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.ml10 {
    margin-left: 10px !important;
}

.ml20 {
    margin-left: 20px !important;
}
.ml40 {
    margin-left: 40px !important;
}

.mr5 {
    margin-right: 5px !important;
}

.mr10 {
    margin-right: 10px;
}

.mr15 {
    margin-right: 15px;
}

.mb2rem {
    margin-bottom: 2rem;
}

.my10 {
    margin: 10px 0;
}

.my20 {
    margin: 20px 0;
}

.my25 {
    margin: 25px 0;
}

.my1rem {
    margin: 1rem 0;
}

.my2rem {
    margin: 2rem 0;
}

.my3rem {
    margin: 3rem 0;
}

.my6rem {
    margin: 6rem 0;
}

.relative {
    position: relative !important;
}
.absolute {
    position: absolute;
}
.floatRight {
    float: right;
}

.screenHeight {
    height: 100vh;
}

.wFull {
    width: 100%;
}
.hFull {
    height: 100%;
}
.objectFit {
    object-fit: cover;
}

.imgCover {
    height: auto;
    width: auto;
    object-fit: cover;
}

.textCenter {
    text-align: center !important;
}

.textRight {
    text-align: right !important;
}

/* common classes */

.cursorPointer {
    cursor: pointer;
}

.spacingTop {
    margin: 2rem 0;
    display: inline-block;
}

/* flex */

.block {
    display: block;
}

.flex {
    display: flex;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.flexJustifyCenter {
    display: flex;
    justify-content: center;
}

.flexCenterStart {
    display: flex;
    align-items: start;
}

.flexBaseline {
    display: flex;
    align-items: baseline;
}

.flexSpaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gap20 {
    gap: 20px;
}
.gap30 {
    gap: 30px;
}
.itemStart {
    align-items: start;
}

.JustifySpaceBetween {
    justify-content: space-between;
}

.JustifySpaceEvenly {
    justify-content: space-evenly;
}

.JustifyCenter {
    justify-content: center;
}
.alignItemCenter {
    align-items: center;
}
.JustifyEnd {
    justify-content: flex-end;
}
.JustifyStart {
    justify-content: flex-start !important;
}

/* ripple effect */
/* .ripple {
    background-position: center !important;
    transition: background 0.8s;
    cursor: pointer !important;
}

.ripple:hover {
    background: #FFFFFF40 radial-gradient(circle, transparent 1%, #FFFFFF40 1%) center/15000%;
}

.ripple:active {
    background-color: #FFFFFF40;
    background-size: 100%;
    transition: background 0.8s;
} */

.errorMessage {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: red;
    padding: 8px;
}


.timerMessage {
    padding: 8px 0;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: var(--bs-red);
}

.customCheckContainer {
    display: flex;
    align-items: center;
    width: fit-content;
}

.customCheckContainer span {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
}

.fromControl {
    margin: 5px 0;
}
.MuiRadio-label.css-pfaso8-JoyRadio-label {
    justify-content: center;
}
label {
    /* color: var(--bs-font-color); */
    display: flex;
    font-family: "Noto Serif", serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.fromControl input,
.fromControl textarea {
    background: var(--bs-white) !important;
    border: 1px solid #f1eedb;
    border-radius: 30px !important;
    color: var(--bs-font-color);
    font-family: "Noto Serif", serif !important;
    font-size: 1rem;
    font-weight: 400 !important;
    min-height: 40px;
    line-height: 1.5rem;
    outline: none;
    padding: 15px 20px;
    transition: .5s ease;
    width: 100%;
}

.fromControl input::placeholder,
input::placeholder,
.fromControl textarea::placeholder,
textarea::placeholder {
    font-size: 14px;
    color: var(--bs-font-color) !important;
    font-weight: 400;
    font-family: var(--sub-heading-family) !important;
}

/* tabs UI  */

.tabsContent {
    border-bottom: 1.09px solid var(--bs-gray-color);
}

.tabsContent button {
    padding: 0 !important;
    margin-right: 25px;
    min-width: auto;
    width: fit-content;
    border-radius: 0 !important;
    font-size: var(--bs-h5FontSize) !important;
    line-height: normal !important;
    color: var(--bs-font-color) !important;
    font-family: var(--main-heading-family) !important;
    text-transform: capitalize !important;
}

.links {
    text-align: center;
    font-weight: 700;
    text-decoration: underline;
    color: var(--bs-darkGray);
    cursor: pointer;
}

.links:hover {
    transition: .5s ease;
    color: var(--bs-blue-color);
}

.fullBtn button {
    width: 100% !important;
}

.disable {
    /* background: var(--bs-disble) !important; */
    opacity: .5 !important;
    pointer-events: none;
}

.wrapper {
    /* height: 100%;
    width: 100%;
    display: table; */
}

.fixed {
    position: fixed !important;
    transition: all .5s !important;
    background-color: var(--bs-black) !important;
    /* animation: smoothScroll .6s; */
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-20%);
    }

    100% {
        transform: translateY(0);
    }
}

.bottomBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bottomBtn.JustifyCenter button {
    justify-content: center;
}

.outer {
    display: flex;
    align-items: center;
    height: calc(100vh - 350px);
    width: 100%;
}

.leftContainer {
    width: 30%;
    padding-right: 40px;
    padding-bottom: 15px;
}

.rightContainer {
    width: 70%;
    padding: 40px 25px 40px 40px;
    background: var(--bs-white);
    max-height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}

.scrollBox {
    overflow-y: auto;
}

.scrollBox::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollBox::-webkit-scrollbar:vertical {
    width: 0;
}

.scrollBox::-webkit-scrollbar:horizontal {
    height: 0;
}

/* 
.scrollBox::-moz-scrollbar {
    -webkit-appearance: none;
}


.scrollBox::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollBox::-webkit-scrollbar:vertical {
    width: 8px;
}

.scrollBox::-webkit-scrollbar:horizontal {
    height: 8px;
}

.scrollBox::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--bs-black) !important;
}

.scrollBox::-moz-scrollbar-track {
    border-radius: 10px;
    background: red!important;
} */

.scrollBox::-moz-scrollbar {
    -webkit-appearance: none;
}

.nodata {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    width: 100%;
    background-color: var(--bs-white);
    border-radius: 20px;
}

.drawerRounded>div:nth-child(3) {
    max-width: 90%;
    margin: auto;
    border-radius: 10px 10px 0 0;
    top: auto;
}

.accordionContent {
    margin: 0 0 20px !important;
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background-color: var(--bs-transparent) !important;
}

.accordionContent>div {
    padding: 20px 16px !important;
    border-radius: 0 !important;
    background-color: #F1EEDB;
}

.accordionContent>div>div {
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important;
}

.accordionContent>div:nth-child(2) {
    padding: 0 16px 10px !important;
    margin-bottom: 20px;
}

.progressBar::before {
    content: "'";
    left: -2px;
    z-index: 99;
    position: absolute;
    height: 8px;
    width: 6px;
    border: 4px solid #eeeeee;
    border-radius: 130px 130px 0 0;
    border-bottom: var(--bs-transparent);
    color: var(--bs-transparent);
    transform: rotate(270deg);
}

.progressBar::after {
    content: "'";
    right: -2px;
    z-index: 99;
    position: absolute;
    height: 8px;
    width: 6px;
    border: 4px solid #eeeeee;
    border-radius: 130px 130px 0 0;
    border-bottom: var(--bs-transparent);
    color: var(--bs-transparent);
    transform: rotate(-270deg);
}

.footerModal div:first-child {
    margin-right: 15px;
}

.largeModal {
    width: 800px;
    max-height: 400px;
    margin: 0 auto;
}

.kioOuter {
    position: relative;
    height: auto;
    display: table;
    width: 100%;
    padding-bottom: 150px;
}

.kioBottomBtnContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 100%; */
    max-width: 100%;
    margin: auto;
    margin-top: 25px;
    padding: 30px;
    background-color: var(--bs-white);
    border-radius: 72px 72px 0 0;
    box-shadow: 0px -1px 15px 0px #0000001F;
}

.kioBottomBtnContainer div:first-child button {
    border-width: 2px !important;
}

.kioBottomBtnContainer div:last-child {
    margin-top: 0;
    margin-left: 20px;
}

.hithere {
    animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {

    10%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0%);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.fixedTab {
    top: 80px;
    left: 0;
    height: 130px;
    z-index: 9;
    position: fixed;
    padding-top: 2rem;
    background-color: var(--bs-lightGray);
    max-width: 100% !important;
    width: 100% !important;
    animation: stuckMoveDown 1s;
    -webkit-animation: stuckMoveDown 1s;
}

@keyframes stuckMoveDown {
    0% {
        transform: translateY(-20%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes smoothScrollBottom {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-1%);
    }
}

.fixedTab>div {
    margin: auto;
    max-width: 100%;
    padding: 0 20px;
}
@media(min-width:1280px) {
    .fixedTab>div {
        margin: auto;
        max-width: 82%;
        /* padding: 0 20px; */
    }
}


.fixedTab input {
    border: 1px solid rgba(63, 68, 68, 0.12) !important;
}

.scroll {
    overflow: auto;
}

.scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll::-webkit-scrollbar:vertical {
    width: 0px;
}

/* media query */
@media(max-width:992px) {
    .largeModal {
        width: 650px;
        max-height: 350px;
    }

    .leftContainer {
        width: 40%;
    }

    .rightContainer {
        width: 60%;
    }
}

@media(max-height:768px) {
    .outer {
        height: auto;
        max-height: auto;
    }
}

@media(max-width:768px) {
    .largeModal {
        width: 550px;
    }

    .outer {
        display: block;
        height: auto !important;
    }

    .leftContainer {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }

    .rightContainer {
        width: 100%;
        max-height: none;
        overflow: inherit;
        border-radius: 0;
        background: var(--bs-transparent);
        padding: 0;
    }

    .fixedTab {
        top: 78px;
    }
}

@media(max-width:640px) {
    label {
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    .spacingTop {
        margin: 7rem 0 2.5rem;
    }

    .spacingX0>div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .spacingX16 {
        padding: 0 16px !important;
    }

    .bottomBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        padding: 30px 20px;
        background-color: var(--bs-white);
        box-shadow: 0px -2px 5px 0px #00000026;
        z-index: 99;
        transition: .5s ease;
    }

    .largeModal {
        width: 100%;
    }

    .accordionContent>div:nth-child(2) {
        padding: 0 16px 10px !important;
    }

    .fixedTab {
        height: 180px;
    }

    .cardAdd .fixedTab {
        top: 78px;
    }
}

@media(max-width:575px) {
    .cardAdd .fixedTab {
        top: 90px;
    }

    .fixedTab {
        top: 130px;
    }
}

@media(max-width:475px) {
    .kioOuter {
        padding-bottom: 200px;
    }

    .kioBottomBtnContainer {
        flex-direction: column;
    }

    .kioBottomBtnContainer div:last-child {
        margin-top: 15px;
    }

}

@media(max-width:375px) {
    .fixedTab {
        top: 130px;
    }

    .cardAdd .fixedTab {
        top: 80px;
    }

    body button {
        min-width: auto !important;
    }
}